'use client'

import { cls } from '@/utils'
import Button from '@/components/button'
import IconChevronRight from '@haiper/icons-svg/icons/outline/chevron-right.svg'
import Link from '@/components/link'
import { SPOTLIGHT_BEST_PRACTICES } from '@/constants'
import SpotlightForm, { SpotlightFormRef } from './form'
import { useCallback, useRef, useState } from 'react'
import { SpotlightFormData } from '@/types'
import useAmplitude from '@/hooks/useAmplitude'
import { postSpotlight } from '@/service/spotlight.service'
import SpotlightSuccessDialog from '@/app/spotlight/profile/upload/dialog'
import SpotlightFAQs from '@/app/spotlight/profile/upload/faqs'
import useNavigation from '@/hooks/useNavigation'

export interface SubmitEntrySpotlightProps {
  className?: string
}

export default function SubmitEntrySpotlight({ className }: SubmitEntrySpotlightProps) {
  const formRef = useRef<SpotlightFormRef>(null)
  const { track } = useAmplitude()
  const navigation = useNavigation()

  const [successDialogVisible, setSuccessDialogVisible] = useState(false)

  const handleSubmit = useCallback(
    async (data: SpotlightFormData & SpotlightFormData['social_accounts']) => {
      track('click:spotlight:submit')
      const submitData = {
        title: data?.title,
        description: data?.description,
        video_key: data?.video_key,
        social_accounts: (data?.social_accounts as any[]).reduce(
          (res, item) => ({
            ...res,
            [item.type]: item.value,
          }),
          {},
        ),
        submit_email: data?.submit_email,
      }

      await postSpotlight(submitData)
      setSuccessDialogVisible(true)
    },
    [track],
  )

  return (
    <div className={cls('flex flex-col gap-8', className)}>
      <div className='rounded-md bg-surface-subdued flex flex-col gap-4 p-6' aria-label='info'>
        <div className='flex flex-col gap-2'>
          <div className='flex items-center justify-between'>
            <span className='text-heading-xl font-bold tracking-45 leading-6'>Spotlight</span>
            <Button variant='transparent'>
              <Link href='/spotlight' className='flex items-center'>
                <span className='tracking-15 px-1'>View spotlight videos</span>
                <IconChevronRight className='text-icon size-5 shrink-0' />
              </Link>
            </Button>
          </div>
          <span className='tracking-15'>
            Spotlight is a featured gallery on the Explore page where we showcase the incredible creations of our
            community! Submit your work for consideration via the form below to have it promoted on Spotlight.
          </span>
        </div>
        <div className='flex flex-col gap-3 w-full' aria-label='best practices'>
          <span className='text-heading-md font-bold tracking-32'>Approval Best Practices</span>
          <div className='flex flex-col w-full gap-3'>
            {SPOTLIGHT_BEST_PRACTICES.map((bestPractice) => {
              return (
                <div key={bestPractice.name} className='flex items-center gap-4'>
                  <div className='size-6 shrink-0 grow-0 flex items-center justify-center'>
                    <img
                      src={bestPractice.icon}
                      alt='Best Practice Item Icon'
                      className='size-full max-h-full max-w-full object-scale-down'
                    />
                  </div>
                  <div className='text-body-sm text-text font-sn tracking-15 text-center'>
                    {bestPractice.description}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <div className='w-full flex flex-col gap-4' aria-label='form'>
        <div className='text-heading-lg tracking-45 font-bold'>Submission</div>
        <div className='' aria-label='widgets'>
          <SpotlightForm
            ref={formRef}
            id='spotlight-form'
            className='w-full max-w-full rounded-xl'
            onSubmit={handleSubmit as any}
          />
        </div>
        <SpotlightFAQs />
      </div>
      {successDialogVisible && (
        <SpotlightSuccessDialog
          onOk={() => {
            setSuccessDialogVisible(false)
            navigation.push('/')
          }}
          onCancel={() => {
            setSuccessDialogVisible(false)
            navigation.push('/spotlight')
          }}
        />
      )}
    </div>
  )
}
