import { cls, whisper } from '@/utils'
import Button from '../button'
import IconChevronRight from '@haiper/icons-svg/icons/outline/chevron-right.svg'
import RequiredStar from '../required-star'
import CreationOutputPicker from '../creation-output-picker'
import { useCallback, useState } from 'react'
import { CreationOutputBasic } from '@/types'

export interface SubmitEntryCreationShowcaseProps {
  className?: string
}

export default function SubmitEntryCreationShowcase({ className }: SubmitEntryCreationShowcaseProps) {
  const [creationOutput, setCreationOutput] = useState<CreationOutputBasic | null>(null)

  const handleSubmit = useCallback(() => {
    whisper('Submission submitted')
  }, [])

  const canSubmit = Boolean(creationOutput)

  return (
    <div className={cls('flex flex-col gap-8', className)}>
      <div className='rounded-md bg-surface-subdued p-6 flex flex-col gap-2' aria-label='info'>
        <div className='flex justify-between items-center'>
          <span className='text-heading-xl leading-6 font-bold tracking-45'>Creation showcase</span>
          <Button variant='transparent'>
            <div className='flex items-center'>
              <span className='px-1 tracking-15'>Details</span>
              <IconChevronRight className='size-5 shrink-0 text-icon' />
            </div>
          </Button>
        </div>
        <span className='tracking-15'>
          Spotlight is a featured gallery on the Explore page where we showcase the incredible creations of our
          community! Submit your work for consideration via the form below to have it promoted on Spotlight.
        </span>
      </div>
      <div className='flex flex-col gap-4' aria-label='form'>
        <div className='text-heading-lg font-bold tracking-45'>Submission</div>
        <div className='w-full flex flex-col gap-2'>
          <span className='flex'>
            <span className='text-body-lg tracking-32'>Creation</span>
            <RequiredStar />
          </span>
          <div>
            <CreationOutputPicker value={creationOutput} onChange={setCreationOutput} />
          </div>
        </div>
      </div>
      <div className='w-full flex justify-center items-center' aria-label='button'>
        <Button variant='primary' className='w-[130px]' disabled={!canSubmit} onClick={handleSubmit}>
          Submit
        </Button>
      </div>
    </div>
  )
}
