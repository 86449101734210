'use client'

import { cls, whisper } from '@/utils'
import IconCupChampionWin from '@haiper/icons-svg/icons/solid/cup-champion-win.svg'
import { FunctionComponent, ReactElement, useMemo, useState } from 'react'
import SubmitEntryEvents from './events'
import SubmitEntrySpotlight from './spotlight'
import SubmitEntryCreationShowcase from './creation-showcase'
import SubmitEntryTemplateSNS from './template-sns'
import ButtonTabs from '../button-tabs'
import { useAtomValue } from 'jotai'
import { parallelRouteOpenAtom } from '@/atoms'

export type SubmitEntryType = 'events' | 'spotlight' | 'creation-showcase' | 'template-sns'

export interface SubmitEntryProps {
  className?: string
  title?: string | ReactElement
  type?: SubmitEntryType
  formOnly?: boolean
}

const tabItems: Array<{ label: string | ReactElement; value: SubmitEntryType }> = [
  {
    label: (
      <span className='flex gap-1 items-center'>
        <IconCupChampionWin />
        Events
      </span>
    ),
    value: 'events',
  },
  {
    label: 'Spotlight',
    value: 'spotlight',
  },
  {
    label: 'Creation showcase',
    value: 'creation-showcase',
  },
]

export default function SubmitEntry({ className, title = 'Submit your entry', type, formOnly }: SubmitEntryProps) {
  const [activeTabKey, setActiveTabKey] = useState<SubmitEntryType>(type ?? tabItems[0].value)
  const Component = useMemo(() => {
    const map: Record<string, FunctionComponent<any>> = {
      events: SubmitEntryEvents,
      spotlight: SubmitEntrySpotlight,
      'creation-showcase': SubmitEntryCreationShowcase,
      'template-sns': SubmitEntryTemplateSNS,
    }

    return map[activeTabKey] ?? map.events
  }, [activeTabKey])

  const parallelRouteOpen = useAtomValue(parallelRouteOpenAtom)
  whisper('parallelRouteOpen', parallelRouteOpen)

  const formControl = <Component />

  if (formOnly) {
    return formControl
  }

  return (
    <div
      className={cls(
        'flex flex-col w-full md:w-[832px] max-w-[832px] gap-4 mx-auto border-2 rounded-[20px] py-8 px-14',
        className,
      )}
    >
      {title ? (
        <div className='font-bold tracking-45 text-2xl text-black dark:text-white' aria-label='title'>
          {title}
        </div>
      ) : null}
      <div className={cls('flex flex-col w-full gap-8')}>
        <div className='' aria-label='tabs'>
          <ButtonTabs items={tabItems} value={activeTabKey} onChange={setActiveTabKey as any} />
        </div>
        <div className='w-full' aria-label='form'>
          {formControl}
        </div>
      </div>
    </div>
  )
}
