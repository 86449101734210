'use client'

import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import GSUpload from '@/components/gs-upload'
import SocialAccountForm, { socialMediaOptions } from '@/components/social-account-form'
import TemplateIcon from '@/components/templates/icon'
import useActiveTemplate from '@/hooks/useActiveTemplate'
import { InnerUploadFile, TemplateSocialMediaPostReq, UploadParams } from '@/types'
import { cls, generateThumbnail, whisper } from '@/utils'
import { useCallback, useMemo, useRef, useState } from 'react'
import useUpload from '@/hooks/useUpload'
import IconUpload from '@haiper/icons-svg/icons/outline/upload.svg'
import Button from '@/components/button'
import { Form } from '@/components/ui/form'
import { submitTemplateSocialMediaPost } from '@/service/template.service'
import { useRouter } from 'next/navigation'
import IconYes from '@/public/assets/yes.svg'
import { useCachedTemplateCategories } from '@/hooks/useTemplateCategories'
import Image from '@/components/image'
import { useSetAtom } from 'jotai'
import { templateSocialMediaRefreshKeyAtom } from '@/atoms'
import { nanoid } from 'nanoid'
import RequiredStar from '../required-star'

const socialMediaOptionBlacklist = ['website']

export interface SubmitEntryTemplateSNSProps {
  className?: string
}

const formSchema = z.object({
  social_accounts: z
    .array(
      z.object({
        type: z.enum(socialMediaOptions.map((item) => item.value) as any),
        value: z.string().url(),
      }),
    )
    .default([]),
})

export default function SubmitEntryTemplateSNS({ className }: SubmitEntryTemplateSNSProps) {
  const { data: activeTemplate } = useActiveTemplate()
  const { data: templateCategories } = useCachedTemplateCategories()

  const category = useMemo(() => {
    return templateCategories?.find((category) => category.category === activeTemplate?.category)
  }, [templateCategories, activeTemplate])

  const [file, setFile] = useState<InnerUploadFile | null>(null)
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      social_accounts: [
        {
          type: 'tiktok',
          value: '',
        },
      ],
    },
  })

  const canSubmit = file?.fileId && form.formState.isValid

  const [submissionId, setSubmissionId] = useState<string | null>(null)
  const routerTimerRef = useRef<NodeJS.Timeout | null>(null)
  const router = useRouter()

  const { upload } = useUpload()

  const uploadCoverImage = useCallback(
    async ({ file, onProgress, onSuccess, onError }: UploadParams) => {
      // conver the video file to the first-frame picture
      const { dataUrl, mime } = await generateThumbnail(file, 'image/jpeg', 1080)
      const blob = await fetch(dataUrl).then((res) => res.blob())
      const newFile = new File([blob], file.name.replace(/\.mp4$/, '.jpg'), { type: mime })

      const res = await upload({
        file: newFile,
        onProgress,
        onSuccess,
        onError,
      })

      return res
    },
    [upload],
  )

  const customPreview = useCallback((file: InnerUploadFile | null) => {
    return (
      <Image
        src={file?.url ?? ''}
        alt='thumbnail'
        className={cls('cursor-default w-full h-full object-contain max-w-full max-h-full md:w-full md:h-full')}
      />
    )
  }, [])

  const submittingRef = useRef(false)
  const setTemplateSocialMediaRefreshKey = useSetAtom(templateSocialMediaRefreshKeyAtom)

  const handleSubmit = useCallback(async () => {
    const values = form.getValues()

    const isValid = await form.trigger()
    if (!isValid) {
      return
    }

    const submitData: TemplateSocialMediaPostReq = {
      cover_image: file?.fileId ?? '',
      cover_image_spec: {
        width: file?.width ?? 0,
        height: file?.height ?? 0,
      },
      social_media_post: {
        platform: '',
        link: '',
      },
    }

    // check social accounts
    const socialAccounts = values.social_accounts
    if (canSubmit && activeTemplate?.template_id) {
      submitData.social_media_post.platform = socialAccounts[0].type
      submitData.social_media_post.link = socialAccounts[0].value ?? ''
      if (submittingRef.current) {
        return
      }
      submittingRef.current = true
      try {
        const res = await submitTemplateSocialMediaPost(activeTemplate?.template_id, submitData)
        submittingRef.current = false
        if (res) {
          // success
          setSubmissionId(res)
          routerTimerRef.current = setTimeout(() => {
            // redirect to the template page
            setTemplateSocialMediaRefreshKey(nanoid())
            router.back()
            // router.push(`/template/${activeTemplate?.template_id}`)
          }, 1000)
        }
      } catch (error) {
        whisper('submit error: ', error)
        submittingRef.current = false
      }
    }
  }, [form, activeTemplate, file, router, canSubmit, setTemplateSocialMediaRefreshKey])

  const requiredStar = <RequiredStar />

  if (submissionId) {
    // show the success message
    return (
      <div className='absolute inset-0 size-full flex items-center justify-center'>
        <div className='relative flex flex-col gap-4 w-full items-center md:w-[832px] md:mx-auto rounded-[20px] py-9 px-14'>
          <div className='p-6 flex items-center justify-center gap-6'>
            <IconYes className='size-20 shrink-0' />
            <div className='text-2xl leading-6 font-bold tracking-32'>Submission successful</div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <Form {...form}>
      <form
        className={cls('relative flex flex-col gap-4 w-full items-center')}
        onSubmit={form.handleSubmit(handleSubmit)}
      >
        <div
          className={cls(
            'w-[95vw] flex flex-col gap-8 md:w-[832px] md:mx-auto border-2 rounded-[20px] py-9 px-14',
            className,
          )}
        >
          <div className='py-2' aria-label='title'>
            <span className='text-heading-2xl leading-6 font-bold tracking-45'>Submit your template creations</span>
          </div>
          <div
            className='rounded-md p-6 flex flex-col gap-2 justify-between bg-surface-subdued'
            aria-label='introduction'
          >
            <div className='flex gap-2 items-center'>
              <TemplateIcon size='sm' src={category?.icon} />
              <span className='text-heading-md font-bold tracking-32 leading-6'>{activeTemplate?.name}</span>
            </div>
            <div className='text-body-md leading-5 tracking-15'>
              <div>Your submission should include content created with {activeTemplate?.name} template.</div>
              <div>Once approved, it will be showcased on the {activeTemplate?.name} template page.</div>
            </div>
          </div>
          <div className='flex flex-col w-full gap-4' aria-label='submission'>
            <div className='text-heading-lg font-bold tracking-45'>Submission</div>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-6' aria-label='widgets'>
              <div className='flex flex-col gap-2' aria-label='video widget'>
                <div className='text-body-lg tracking-32 flex items-center gap-1'>
                  <span>Video</span>
                  {requiredStar}
                </div>
                <GSUpload
                  className='w-full min-h-100'
                  file={file}
                  fileType='video'
                  customUpload={uploadCoverImage}
                  customPreview={customPreview}
                  emptyIcon={IconUpload}
                  emptyIconClassName='size-10 text-icon-subdued'
                  emptyText={
                    <div className='flex flex-col items-center'>
                      <span className='text-body-lg tracking-32'>Upload video</span>
                      <span className='text-text-subdued tracking-15'>MP4, MKV, MOV, ≤ 100MB, ≥ 2s</span>
                    </div>
                  }
                  previewClassName='md:w-full md:h-full'
                  minDuration={2} // 2s
                  maxFileSize={100 * 1024 * 1024} // 100MB
                  errorTip='Videos must be longer than 2 seconds, smaller than 100MB, and in MP4, MKV, or MOV format.'
                  onChange={setFile}
                />
              </div>
              <div className='flex flex-col gap-2' aria-label='media post link widget'>
                <div className='text-body-lg tracking-32 flex items-center gap-1'>
                  <span>Social media post link</span>
                  {requiredStar}
                </div>
                <div className='w-full flex flex-col gap-2'>
                  <span className='text-text-subdued tracking-15'>We will promote the post links you provide here</span>
                  <SocialAccountForm
                    form={form}
                    fieldName='social_accounts'
                    maxCount={1}
                    deleteButtonClassName='hidden'
                    blacklist={socialMediaOptionBlacklist}
                    placeholder='Post URL'
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='w-full flex justify-center items-center'>
            <Button variant='primary' className='w-[130px]' disabled={!canSubmit} onClick={handleSubmit}>
              Submit
            </Button>
          </div>
        </div>
      </form>
    </Form>
  )
}
