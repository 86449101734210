import { useForm } from 'react-hook-form'
import { Input } from '@/components/ui/input'
import { RefObject, forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { InnerUploadFile, Spotlight, PoNVoid, UploadParams } from '@/types'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import GSUpload from '@/components/gs-upload'
import Button from '@/components/button'
import { dataURLtoBlob, generateThumbnail, getVideoDuration, preventDefault, cls, whisper } from '@/utils'
import { uploadFileToUrl } from '@/service/upload.service'
import useMyProfile from '@/hooks/useMyProfile'
import IconUpload from '@haiper/icons-svg/icons/outline/upload.svg'
import { Textarea } from '@/components/ui/textarea'
import IconInfo from '@/public/assets/info.svg'
import { Tooltip, TooltipProvider, TooltipTrigger, TooltipContent } from '@/components/ui/tooltip'
import usePublicSignedUploadURL from '@/hooks/usePublicSignedUploadURL'
import LoginMask from '@/components/login-mask'
import { socialMediaOptions } from '@/components/social-account-form'
import dayjs from 'dayjs'
import Image from '@/components/image'
import Dialog from '@/components/dialog'
import { useRouter } from 'next/navigation'
import RequiredStar from '@/components/required-star'

const formSchema = z.object({
  title: z.string().min(1, 'Required').default(''),
  video_key: z.string().min(1, 'Required').default(''),
  thumbnail_key: z.string().min(1, 'Required').default(''),
  description: z.string().min(1, 'Required').default(''),
  social_accounts: z
    .array(
      z.object({
        type: z.enum(socialMediaOptions.map((item) => item.value) as any),
        value: z.string().url().optional().or(z.literal('')),
      }),
    )
    .default([]),
  submit_email: z
    .string()
    .email({
      message: 'Please enter a valid email address',
    })
    .default(''),
})

export interface SpotlightFormProps {
  id?: string
  className?: string
  onSubmit: (data: Spotlight) => PoNVoid
}

export interface SpotlightFormRef {
  submit: () => void
  formRef: RefObject<HTMLFormElement>
}

const inputClassName = 'rounded-md border-border shadow-xs hover:border-border-hover'

const dialogButtonProps = {
  className: 'flex-1',
}

const SpotlightForm = forwardRef(({ className, onSubmit, id }: SpotlightFormProps, ref) => {
  const { data: profile } = useMyProfile()
  const [updateProfileDialogVisible, setUpdateProfileDialogVisible] = useState(false)

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      title: '',
      video_key: '',
      thumbnail_key: '',
      description: '',
      submit_email: profile?.email ?? '',
    },
  })

  const profileEditUrl = useMemo(() => {
    return profile?.user_id ? `/profile/${profile?.user_id}/edit` : ''
  }, [profile])

  const router = useRouter()

  const editProfile = useCallback(() => {
    router.push(profileEditUrl)
  }, [router, profileEditUrl])

  const { data: spotlightSignedUploadUrls, isValidating: signedUrlLoading } = usePublicSignedUploadURL()

  // const handleUploadVideo = useCallback(
  //   async ({ file, onProgress, onSuccess, onError }: UploadParams) => {
  //     await uploadFileToUrl({
  //       file,
  //       onProgress,
  //       onSuccess,
  //       onError,
  //       key: spotlightSignedUploadUrls?.video_key ?? '',
  //       url: spotlightSignedUploadUrls?.video_url ?? '',
  //     })
  //   },
  //   [spotlightSignedUploadUrls],
  // )

  const handleUploadThumbnail = useCallback(
    async ({ file, onProgress, onSuccess, onError }: UploadParams) => {
      let jpgFile = file
      if (jpgFile.type !== 'image/jpeg') {
        const res = await generateThumbnail(file, 'image/jpeg')
        const blob = dataURLtoBlob(res.dataUrl)
        jpgFile = new File([blob], file.name, { type: 'image/jpeg' })
      }
      await uploadFileToUrl({
        file: jpgFile,
        onProgress,
        onSuccess,
        onError,
        key: (spotlightSignedUploadUrls?.video_key ?? '') + '.thumbnail',
        url: spotlightSignedUploadUrls?.thumbnail_url ?? '',
      })
    },
    [spotlightSignedUploadUrls],
  )

  const formRef = useRef<HTMLFormElement>(null)

  useImperativeHandle(ref, () => {
    return {
      submit: () => formRef.current?.submit(),
      formRef,
    }
  })

  const [videoFile, setVideoFile] = useState<InnerUploadFile | null>(null)
  const [thumbnailFile, setThumbnailFile] = useState<InnerUploadFile | null>(null)

  useEffect(() => {
    form.setValue('video_key', videoFile?.fileId ?? '')
    form.clearErrors('video_key')
    setVideoUploadError(null)
  }, [videoFile, form])

  useEffect(() => {
    form.setValue('thumbnail_key', thumbnailFile?.fileId ?? '')
  }, [thumbnailFile, form])

  const checkEmail = useCallback(() => {
    if (profile && !profile.email) {
      setUpdateProfileDialogVisible(true)
      router?.prefetch(profileEditUrl)
    }
  }, [profile, router, profileEditUrl])

  useEffect(() => {
    form.setValue('submit_email', profile?.email ?? '')
    if (profile) {
      checkEmail()
    }
  }, [profile, form, checkEmail])

  const [submitting, setSubmitting] = useState(false)

  const [videoUploading, setVideoUploading] = useState(false)
  const [thumbnailUploading, setThubnailUploading] = useState(false)

  const requiredStar = <RequiredStar />

  const [videoUploadError, setVideoUploadError] = useState<string | null>('')

  const isNewUser = useCallback(() => {
    const date = dayjs(profile?.create_time || '').unix()
    const now = dayjs().unix()
    return now - date < 24 * 60 * 60
  }, [profile])

  const handleSubmit = useCallback(
    async (data: z.infer<typeof formSchema>) => {
      setSubmitting(true)
      try {
        const duration = await getVideoDuration(videoFile?.url ?? '')

        if (duration < 12) {
          form.setError('video_key', {
            message: 'The video should be at least 12 seconds',
          })
          setSubmitting(false)
          setVideoUploadError('The video should be at least 12 seconds')
          document.getElementById('spotlight-form')?.scrollIntoView()
          return
        }
        const { thumbnail_key, ...submitData } = data ?? {}
        await onSubmit?.(submitData as any)
        setSubmitting(false)
      } catch (error) {
        setSubmitting(false)
        throw error
      }
    },
    [onSubmit, videoFile, form],
  )

  return (
    <Form {...form}>
      <form
        ref={formRef}
        id={id}
        className={cls('relative flex flex-col gap-4', className)}
        onSubmit={form.handleSubmit(handleSubmit)}
      >
        <div className='w-full flex flex-col md:flex-row gap-6'>
          <div className='flex-1' aria-label='left container'>
            <FormField
              control={form.control}
              name='video_key'
              disabled={signedUrlLoading}
              render={() => (
                <FormItem id='spotlight-form'>
                  <FormLabel className='text-body-lg space-x-1'>
                    Video
                    {requiredStar}
                    {videoUploadError && (
                      <span className='text-text-critical text-body-sm px-1'>{videoUploadError}</span>
                    )}
                  </FormLabel>
                  <FormControl>
                    <GSUpload
                      className='w-full min-h-100'
                      file={videoFile}
                      fileType='video'
                      emptyIcon={IconUpload}
                      emptyIconClassName='size-10 text-icon-subdued'
                      emptyText={
                        <div className='flex flex-col items-center'>
                          <span className='text-body-lg tracking-32'>Upload video</span>
                          <span className='text-text-subdued tracking-15'>MP4, MKV, MOV, ≤ 100MB, ≥ 12s</span>
                        </div>
                      }
                      previewClassName='md:w-full md:h-full'
                      minDuration={12} // 2s
                      maxFileSize={1 * 1024 * 1024 * 1024} // 1GB
                      errorTip='Videos must be longer than 2 seconds, smaller than 100MB, and in MP4, MKV, or MOV format.'
                      onChange={setVideoFile}
                    />
                  </FormControl>
                  <FormMessage className='h-4 m-0' />
                </FormItem>
              )}
            />
          </div>
          <div className='flex-1 flex flex-col gap-4 w-full' aria-label='right container'>
            <FormField
              control={form.control}
              name='title'
              render={({ field }) => (
                <FormItem>
                  <FormLabel className='text-body-lg'>Title{requiredStar}</FormLabel>
                  <FormControl>
                    <Input className={inputClassName} autoComplete='off' placeholder='' {...field} />
                  </FormControl>
                  <FormMessage className='h-4 m-0' />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name='thumbnail_key'
              disabled={signedUrlLoading}
              render={() => (
                <FormItem>
                  <FormLabel className='text-body-lg'>Cover Image{requiredStar}</FormLabel>
                  <FormControl>
                    <GSUpload
                      file={thumbnailFile}
                      fileType='image'
                      className={cls(
                        'bg-surface hover:bg-surface-hover rounded-lg border border-solid border-border hover:border-border-hover',
                        thumbnailFile ? 'w-full aspect-video h-auto' : '',
                      )}
                      customUpload={handleUploadThumbnail}
                      uploading={thumbnailUploading}
                      setUploading={setThubnailUploading}
                      maxFileSize={1 * 1024 * 1024 * 1024}
                      customPreview={(file) => {
                        return (
                          <Image
                            src={file.url}
                            alt='thumbnail'
                            className='pointer-events-none w-full h-auto max-h-full object-cover aspect-video'
                          />
                        )
                      }}
                      onChange={setThumbnailFile}
                    />
                  </FormControl>
                  <FormMessage className='h-4 m-0' />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name='description'
              render={({ field }) => (
                <FormItem>
                  <FormLabel className='text-body-lg'>
                    Description
                    {requiredStar}
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger>
                          <IconInfo
                            aria-label='tips'
                            className='text-icon-disable size-6 inline-flex ml-1 cursor-pointer'
                            onClick={preventDefault}
                          />
                        </TooltipTrigger>
                        <TooltipContent
                          className='w-[195px] px-3 py-2 bg-icon-on-bright-color text-text-on-color'
                          align='start'
                        >
                          <span className='text-body-md'>
                            Feel free to describe your video. Include your creative vision, the tools you used, and any
                            other relevant details.
                          </span>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  </FormLabel>
                  <FormControl>
                    <Textarea
                      className={inputClassName}
                      autoComplete='off'
                      placeholder=''
                      maxLength={2000}
                      {...field}
                    />
                  </FormControl>
                  <div className='flex flex-row-reverse items-center w-full justify-between h-4'>
                    <FormDescription className='flex justify-end items-start m-0'>
                      {field.value.length}/2000
                    </FormDescription>
                    <FormMessage className='h-4 m-0' />
                  </div>
                </FormItem>
              )}
            />
          </div>
        </div>
        <div className='w-full flex pt-5 justify-center'>
          <Button
            disabled={submitting || form.formState.isSubmitting || isNewUser()}
            type='submit'
            variant='primary'
            className='w-full md:w-[126px] rounded-md'
            loading={submitting}
            onClick={checkEmail}
          >
            Submit
          </Button>
        </div>
        <LoginMask />
      </form>
      <Dialog
        open={updateProfileDialogVisible}
        title='Update Email'
        titleClassName='mb-6'
        className={cls('gap-0 md:w-[343px] p-4')}
        footerClassName='mt-[30px]'
        okText='Update'
        okButtonProps={dialogButtonProps}
        cancelButtonProps={dialogButtonProps}
        onOpenChange={setUpdateProfileDialogVisible}
        onOk={editProfile}
      >
        <span>Please update your email before submitting a spotlight video.</span>
      </Dialog>
    </Form>
  )
})

SpotlightForm.displayName = 'SpotlightForm'

export default SpotlightForm
