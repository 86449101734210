import { cls } from '@/utils'
import Tabs, { TabsProps } from '../tabs'

export default function ButtonTabs({
  className,
  listClassName,
  itemClassName,
  activeItemClassName,
  ...props
}: Omit<TabsProps, 'variant'>) {
  return (
    <Tabs
      variant='outline'
      className={cls('bg-transparent', className)}
      listClassName={cls('bg-transparent flex justify-start gap-4', listClassName)}
      itemClassName={cls('px-3 py-1 border border-b-2 border-border rounded-[20px] font-normal', itemClassName)}
      activeItemClassName={cls('border-border-active bg-surface', activeItemClassName)}
      {...props}
    />
  )
}
