import type { Creation, Pagination, PaginationQuery } from '@/types'
import { rest } from '@/utils'

export const getMarketingEvents = async (
  params: PaginationQuery<{ event_id?: string }>,
): Promise<Pagination<Creation>> => {
  const res = await rest.get('/v1/events', { params })
  return res.data
}

export const submitWorkToEvent = async (event_id: string, work_id: string) => {
  const res = await rest.post(`/v1/event/${event_id}/submit/${work_id}`)
  return res.data
}
