import { FAQConfig } from '@/types'
import { useCachedConfig } from './useConfig'

// const useFAQs = () => {
//   return useConfig<FAQConfig>('faqs')
// }

export const useCachedFAQs = () => {
  return useCachedConfig<FAQConfig>('faqs')
}

// export default useFAQs
