import { PoNVoid } from '@/types'
import { PropsWithChildren } from 'react'
import { useInView } from 'react-intersection-observer'

export interface InfiniteItemProps {
  onShow: () => PoNVoid
}

export default function InfiniteItem({ onShow, children }: PropsWithChildren<InfiniteItemProps>) {
  const { ref } = useInView({
    threshold: 0.75,
    triggerOnce: true,
    onChange(inView, entry) {
      if (inView) {
        onShow?.()
      }
    },
  })

  return <div ref={ref}>{children}</div>
}
