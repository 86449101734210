import { cls } from '@/utils'
import { Tooltip, TooltipProvider, TooltipTrigger, TooltipContent } from '@/components/ui/tooltip'
import IconInfo from '@haiper/icons-svg/icons/outline/info-circle.svg'

export interface TipsIconProps {
  className?: string
  contentClassName?: string
  tips: string
}

export default function TipsIcon({ className, tips, contentClassName }: TipsIconProps) {
  if (!tips) {
    return null
  }
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger>
          <IconInfo className={cls('size-4 text-icon-subdued', className)} />
        </TooltipTrigger>
        <TooltipContent
          className={cls('text-text-on-color w-[208px] text-body-md text-center tracking-15', contentClassName)}
        >
          {tips}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}
