import { ChangeEvent, ComponentProps, forwardRef, useCallback, useEffect, useImperativeHandle, useRef } from 'react'
import { Textarea as BaseTextarea } from '../ui/textarea'
import { TextareaElement } from '@lemonsqueezy/wedges'

// export interface TextareaProps extends HTMLAttributes<HTMLTextAreaElement> {
export interface TextareaProps extends ComponentProps<'textarea'> {
  autoSize?: boolean
}

const Textarea = forwardRef<TextareaElement, TextareaProps>(
  ({ value, onChange, autoSize, ...props }: TextareaProps, ref) => {
    const textareaRef = useRef<HTMLTextAreaElement>(null)
    const timerRef = useRef<NodeJS.Timeout>()

    useImperativeHandle(ref, () => textareaRef.current!, [])

    const autoSizeTextarea = useCallback(() => {
      const textarea = textareaRef?.current
      if (autoSize && textarea) {
        textarea.style.height = 'auto'
        textarea.style.height = `${textarea.scrollHeight}px`
      }
    }, [autoSize])

    useEffect(() => {
      setTimeout(() => {
        autoSizeTextarea()
      }, 0)
    }, [value, autoSizeTextarea])

    const handleChange = useCallback(
      (e: ChangeEvent<HTMLTextAreaElement>) => {
        onChange?.(e)

        if (timerRef.current) {
          clearTimeout(timerRef.current)
        }
        timerRef.current = setTimeout(autoSizeTextarea, 0)
      },
      [onChange, autoSizeTextarea],
    )

    return <BaseTextarea ref={textareaRef as any} value={value} onChange={handleChange} {...props} />
  },
)

Textarea.displayName = 'Textarea'
export default Textarea
