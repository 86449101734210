import Link from '@/components/link'
import { DISCORD_LINK } from '@/constants'
import { useCachedFAQs } from '@/hooks/useFAQs'
import { cls } from '@/utils'

export interface SpotlightFAQsProps {
  className?: string
}

export default function SpotlightFAQs({ className }: SpotlightFAQsProps) {
  const { data: allFAQs } = useCachedFAQs()

  const faqs = allFAQs?.spotlight ?? []

  return (
    <div className='flex flex-col items-center gap-4 pt-6' aria-label='FAQ'>
      <div className='text-heading-lg font-bold tracking-45' aria-label='FAQ title'>
        FAQs
      </div>
      <div className='text-text-subdued text-heading-md text-center font-bold tracking-32'>
        <span>Can’t find the answer?</span>
        <br />
        <span>
          Ask for help in our
          <Link
            href={DISCORD_LINK}
            target='_blank'
            rel='noopener noreferer'
            className='text-text-interactive hover:text-text-interactive-hover pl-1 hover:no-underline'
          >
            Discord community
          </Link>
        </span>
      </div>
      <div
        className='text-body-md tracking-15 flex flex-col gap-6 bg-surface-hover dark:bg-surface-subdued rounded-xl p-6'
        aria-label='FAQ content'
      >
        {(faqs ?? []).map((faq, index) => {
          return (
            <div key={[faq.question, index].join(',')} className='flex flex-col gap-2 list-inside'>
              <div className='text-heading-md font-bold tracking-32 flex items-start' aria-label='FAQ Question'>
                <span aria-label='index' className='w-6'>
                  {index + 1}.
                </span>
                <span aria-label='content'>{faq.question}</span>
              </div>
              <div className='text-body-md tracking-15 pl-6' aria-label='FAQ Answer'>
                {faq.answer}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
