// @deprecated
import {
  // getCreations,
  // getUserCreations,
  getUserCreationsSegment,
} from '@/service/creation.service'
import useService from '@banyudu/use-service'
import { useInfiniteSegment } from './useInfinite'

// const useCreations = useService(getCreations)

const useUserCreationsSegment = useService(getUserCreationsSegment, (params) =>
  Boolean(params.user_id),
)

export const useInfiniteUserCreationsSegment = useInfiniteSegment(
  useUserCreationsSegment,
  {
    rowKey: 'creation_id',
  },
)

// export const useUserCreations = useService(getUserCreations, Boolean)

// export default useCreations
