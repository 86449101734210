import { CreationOutput, CreationOutputBasic } from '@/types'
import { cls, preventDefaultAndStopPropagation, whisper } from '@/utils'
import IconCursorBox from '@haiper/icons-svg/icons/outline/cursor-box.svg'
import Image from '../image'
import Button from '../button'
import { useCallback, useState } from 'react'
import Dialog from '@/components/dialog'
import CreationOutputGallery from './creation-output-gallery'

export interface CreationOutputPickerProps {
  className?: string
  value?: CreationOutputBasic | null
  onChange?: (value: CreationOutputBasic | null) => void
}

export default function CreationOutputPicker({ className, value, onChange }: CreationOutputPickerProps) {
  const [showModal, setShowModal] = useState(false)

  const handleClick = useCallback(() => {
    setShowModal(true)
  }, [])

  const onPick = useCallback(
    (output: CreationOutputBasic) => {
      whisper('Creation output picked', output)
      onChange?.(output)
      setShowModal(false)
    },
    [onChange],
  )

  const handleRemove = useCallback(
    (e: any) => {
      preventDefaultAndStopPropagation(e)
      onChange?.(null)
    },
    [onChange],
  )

  return (
    <div
      className={cls(
        'relative border border-border rounded-md flex items-center justify-center p-4 h-[405px] w-full overflow-hidden',
        className,
      )}
    >
      <div
        className='size-full flex justify-center items-center cursor-pointer'
        aria-label='content'
        onClick={handleClick}
      >
        {value ? (
          <div className='relative bg-surface-subdued flex items-center justify-center' aria-label='preview'>
            <Image
              src={value.thumbnail_url ?? value.media_url}
              alt='creation output'
              className='w-full max-h-full object-scale-down'
            />
            <div className='absolute top-3 right-3' aria-label='tools'>
              <Button variant='outline' className='py-1 px-2' aria-label='remove' onClick={handleRemove}>
                <div className='flex items-center'>
                  <IconCursorBox className='text-icon-subdued size-5' />
                  <span className='px-1'>Delete</span>
                </div>
              </Button>
            </div>
          </div>
        ) : (
          <div className='flex flex-col gap-2 items-center'>
            <div className='size-10 shrink-0' aria-label='icon'>
              <IconCursorBox className='text-icon-subdued size-8' />
            </div>
            <div className='flex flex-col items-center gap-1 text-center'>
              <span className='text-body-lg tracking-32'>Select from creations</span>
              <div className='text-body-md'>
                <div className='whitespace-pre-wrap'>This event supports photo and video submissions</div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className='absolute size-0' aria-label='creation output dialog'>
        <Dialog
          open={showModal}
          className='w-[343px] md:w-[886px]'
          title='Select from creations'
          footer={null}
          onOpenChange={setShowModal}
        >
          <div className='flex flex-col gap-8 pt-2 items-center'>
            <CreationOutputGallery onItemClick={onPick} />
          </div>
        </Dialog>
      </div>
    </div>
  )
}
