import { useCachedMarketingEvents } from '@/hooks/useMarketingEvents'
import { cls, whisper } from '@/utils'
import Scrollable from '../scrollable'
import EventCard from '../event-card'
import { useCallback, useEffect, useMemo, useState } from 'react'
import Triangle from '../triangle'
import Button from '../button'
import IconChevronRight from '@haiper/icons-svg/icons/outline/chevron-right.svg'
import useEventAction from '@/hooks/useEventAction'
import useAmplitude from '@/hooks/useAmplitude'
import CreationOutputPicker from '../creation-output-picker'
import RequiredStar from '../required-star'
import TipsIcon from '../tips-icon'
import Textarea from '../textarea'
import { CreationOutputBasic } from '@/types'
import Markdown from '../markdown'
import { submitWorkToEvent } from '@/service/event.service'
import { toast } from '../ui/use-toast'
import { useRouter } from 'next/navigation'

export interface SubmitEntryEventsProps {
  className?: string
}

const maxDescriptionLength = 200

export default function SubmitEntryEvents({ className }: SubmitEntryEventsProps) {
  const { data: marketingEvents } = useCachedMarketingEvents()
  const [activeEventId, setActiveEventId] = useState<string | null>(null)
  const [description, setDescription] = useState<string>('')
  const activeEvent = useMemo(
    () => marketingEvents?.find((event) => event.event_id === activeEventId) ?? null,
    [marketingEvents, activeEventId],
  )
  const router = useRouter()

  // Set the first event as active by default
  useEffect(() => {
    if (marketingEvents && !activeEventId) {
      setActiveEventId(marketingEvents[0].event_id)
    }
  }, [marketingEvents, activeEventId])

  const { handleClick: handleEventClick } = useEventAction(activeEvent)
  const { track } = useAmplitude()

  const openEventDetails = useCallback(
    async (e: any) => {
      track('click:event:card', {
        event_id: activeEvent?.event_id,
        source: 'submit-entry',
      })
      handleEventClick?.(e)
    },
    [handleEventClick, track, activeEvent],
  )

  const [creationOutput, setCreationOutput] = useState<CreationOutputBasic | null>(null)

  const handleSubmit = useCallback(async () => {
    whisper('Submission submitted')
    if (!activeEventId || !creationOutput) {
      return
    }

    const res = await submitWorkToEvent(activeEventId!, creationOutput!.id)
    whisper('Submission response: ', res)
    toast({
      title: 'Submission successful',
    })

    router.back()
  }, [activeEventId, creationOutput, router])

  const canSubmit = Boolean(creationOutput && description)

  return (
    <div className={cls('flex flex-col gap-8', className)}>
      <div className='flex flex-col' aria-label='info'>
        <span className='text-heading-lg font-bold text-black dark:text-white mb-4'>Select Event</span>
        <Scrollable className='gap-4 px-1'>
          {marketingEvents?.map((event) => {
            const isActive = event.event_id === activeEventId
            return (
              <div key={event.event_id} className={cls('flex flex-col items-center gap-0.5 w-70')}>
                <div
                  key={event.event_id}
                  className={cls(
                    'rounded-lg flex flex-col items-center justify-center',
                    isActive ? 'border-primary border' : 'border-border border',
                  )}
                >
                  <EventCard
                    key={event.event_id}
                    hideStatus
                    hideButton
                    data={event}
                    source='submit-entry'
                    className={cls('rounded-[10px]')}
                    onClick={() => setActiveEventId(event.event_id)}
                  />
                </div>
                <Triangle className={cls('', isActive ? 'text-surface-subdued' : 'text-transparent')} />
              </div>
            )
          })}
        </Scrollable>
        <div className='w-full p-4 rounded-md bg-surface-subdued flex flex-col gap-2' aria-label='event info'>
          <div className='w-full flex justify-between items-center' aria-label='event info toolbar'>
            <span className='text-heading-md font-bold tracking-32'>{activeEvent?.title}</span>
            <Button variant='transparent' onClick={openEventDetails}>
              <div className='flex'>
                <span className='px-1 tracking-15'>Details</span>
                <IconChevronRight className='size-5 shrink-0 text-icon' />
              </div>
            </Button>
          </div>
          <div className='' aria-label='event info body'>
            {activeEvent?.description && <Markdown>{activeEvent?.description}</Markdown>}
          </div>
        </div>
      </div>
      <div className='flex flex-col gap-4' aria-label='form'>
        <div className='text-heading-lg tracking-45 font-bold'>Submission</div>
        <div className='flex flex-col md:flex-row gap-6' aria-label='widgets'>
          <div className='flex flex-col gap-2 md:flex-1' aria-label='creation widget'>
            <span className='text-body-lg' aria-label='label'>
              Creation
              <RequiredStar />
            </span>
            <CreationOutputPicker value={creationOutput} onChange={setCreationOutput} />
          </div>
          <div className='flex flex-col gap-2 md:flex-1' aria-label='description widget'>
            <div className='flex items-center' aria-label='label'>
              <span className='text-body-lg'>Description</span>
              <TipsIcon tips='' className='ml-1' />
            </div>
            <Textarea
              className='border'
              value={description}
              maxLength={maxDescriptionLength}
              onChange={(e) => setDescription(e.target.value)}
            />
            <div className='flex justify-end'>
              <span className='text-text-subdued' aria-label='char count'>
                {description.length}
                <span className='px-1'>/</span>
                {maxDescriptionLength}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className='w-full flex justify-center items-center'>
        <Button variant='primary' className='w-[130px]' disabled={!canSubmit} onClick={handleSubmit}>
          Submit
        </Button>
      </div>
    </div>
  )
}
