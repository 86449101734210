import { SELF_USER_ID } from '@/constants'
import { useInfiniteUserCreationsSegment } from '@/hooks/useCreations'
import { Creation, CreationOutputBasic, PoNVoid } from '@/types'
import { cls, whisper } from '@/utils'
import { useCallback, useMemo, useState } from 'react'
import ButtonTabs from '../button-tabs'
import { flatMap } from 'lodash-es'
import Video from '../video'
import Image from '../image'
import Infinite from '../infinite'

export interface CreationOutputGalleryProps {
  className?: string
  onItemClick?: (output: CreationOutputBasic) => PoNVoid
}

const filters = [
  {
    name: 'Video',
    func: (creation: Creation) => creation.output_type === 'video',
  },
  {
    name: 'Image',
    func: (creation: Creation) => creation.output_type === 'image',
  },
]

export default function CreationOutputGallery({ className, onItemClick }: CreationOutputGalleryProps) {
  const {
    data: creationsRes,
    isValidating: creationsLoading,
    hasMore,
    loadMore,
  } = useInfiniteUserCreationsSegment({
    user_id: SELF_USER_ID,
    limit: 40,
  })

  whisper('creationsLoading is: ', creationsLoading)

  const [activeFilterName, setActiveFilterName] = useState(filters[0].name)

  const activeFilter = useMemo(
    () => filters.find((filter) => filter.name === activeFilterName)?.func,
    [activeFilterName],
  )
  const creations: Creation[] = useMemo(
    () => creationsRes?.records?.filter((item) => !activeFilter || activeFilter(item)) || [],
    [creationsRes, activeFilter],
  )

  const tabItems = useMemo(() => {
    return filters.map((filter) => ({
      label: filter.name,
      value: filter.name,
    }))
  }, [])

  const galleryItems = useMemo(() => {
    return flatMap(creations, (creation) => {
      return (creation.outputs ?? []).map((output) => ({
        ...output,
        creation,
      }))
    })
  }, [creations])

  return (
    <div className={cls('flex flex-col gap-4 w-full', className)}>
      <div className='w-full' aria-label='filters'>
        <ButtonTabs items={tabItems} value={activeFilterName} onChange={(value) => setActiveFilterName(value)} />
      </div>
      <Infinite
        className={cls(
          'grid grid-cols-1 md:grid-cols-2 gap-2 overflow-y-auto h-max max-h-[calc(90vh-150px)] min-h-100',
        )}
        aria-label='outputs gallery'
        dataSource={galleryItems}
        loadMore={loadMore}
        rowKey='id'
        loading={creationsLoading}
        hasMore={hasMore}
        itemRenderer={(output) => {
          const handleClick = () => {
            onItemClick?.(output)
          }
          return (
            <div
              key={output.id}
              className='aspect-video rounded-md bg-surface-subdued overflow-hidden w-full cursor-pointer'
            >
              {output.creation.output_type === 'video' ? (
                <Video
                  playOnHover
                  playsInline
                  loop
                  src={output.media_url}
                  poster={output.thumbnail_url}
                  className='w-full h-full object-cover'
                  onClick={handleClick}
                />
              ) : (
                <Image
                  src={output.media_url}
                  alt='output'
                  className='w-full h-full object-cover'
                  onClick={handleClick}
                />
              )}
            </div>
          )
        }}
      />
    </div>
  )
}
