import Button from '@/components/button'
import Dialog from '@/components/dialog'
import IconCheckRadio from '@haiper/icons-svg/icons/outline/check-radio.svg'

export interface SpotlightSuccessDialogProps {
  onOk?: () => void
  onCancel?: () => void
}

export default function SpotlightSuccessDialog({
  onOk,
  onCancel,
}: SpotlightSuccessDialogProps) {
  return (
    <Dialog
      open
      className='w-100'
      footer={null}
      title={
        <div className='flex flex-col gap-4'>
          <div
            aria-label='icon'
            className='size-12 rounded-full p-3 bg-surface-success'
          >
            <IconCheckRadio className='size-full text-icon-success' />
          </div>
          <span className='text-heading-lg font-bold tracking-45'>
            Submission Successful
          </span>
        </div>
      }
      onOpenChange={onCancel}
    >
      <div className='pt-4 gap-8 flex flex-col'>
        <span className='text-body-md text-text-subdued tracking-15'>
          Thank you for sharing your work with us! Your content has been
          successfully submitted. If your submission is selected, we'll reach
          out to you via email. Stay tuned!
        </span>
        <Button variant='primary' className='rounded-md' onClick={onOk}>
          Back to Explore
        </Button>
      </div>
    </Dialog>
  )
}
